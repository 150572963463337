<template>
  <div class="">
    <b-skeleton-wrapper :loading="patient == null">
      <template #loading>
        <b-skeleton-table
          :rows="5"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </template>

      <b-table
        :items="[solePatient]"
        class="text-dark"
        stacked
        bordered
        responsive
      ></b-table>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { geroendpoints } from "@/utils/endpoints";
import { gerohttp } from "@/utils/gerocarehttp";
import _ from "lodash";

export default {
  name: "GeneralVisitTracker",
  props: ["patientId"],
  data() {
    return {
      patient: null,
    };
  },
  computed: {
    solePatient() {
      let patient = _.pick(this.patient, [
        "firstname",
        "midname",
        "lastname",
        "sex",
        "age",
        "email",
        "phone",
        "religion",
        "marital_status",
        "address",
        "deceased",
        "emergency_email",
        "emergency_phone",
        "next_due_date",
        "created_at",
      ]);

      _.update(patient, "next_due_date", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(patient, "created_at", (value) =>
        value ? this.formatDateTime(value) : value
      );

      return patient;
    },
  },
  methods: {
    fetchPatient() {
      gerohttp
        .get(geroendpoints.FETCH_PATIENT.replace(":id", this.patientId), {
          params: {
            include: "user,doctor",
          },
        })
        .then((response) => {
          this.patient = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
  mounted() {
    this.fetchPatient();
  },
};
</script>
